




























































































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import validator from "validator";
import countriesList from "@/assets/data/countries.json";
import useBase64 from "@/use/base64";
import useSelectItems from "@/use/selectItems";

interface StepError {
  step: number;
  error: string;
}

const { toBase64 } = useBase64();

export default defineComponent({
  setup(_, { root, emit }) {
    const map = ref<HTMLElement | null>(null);
    const search = ref<HTMLInputElement | null>(null);

    const { languageItems } = useSelectItems({ root });

    const state = reactive({
      loading: false,
      success: false,
      error: false,
      lecturerPhoto: "",
      oldImage: null as null | { id: string },
      empty: false,
      table: false,
      loaded: false,
      items: [],
    });

    const model = reactive<{
      data: any;
    }>({
      data: {},
    });

    // Start fetch degree items list

    const fetchDegree = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get("degree")
        .then(({ data: { degrees } }) => {
          state.empty = false;
          const result = degrees.map((degree: any, index: number) => ({
            id: index,
            name: "",
            ...degree,
          }));
          state.items = result.reduce(
            (a: any, o: any) => (a.push(o.name), a),
            []
          );
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.empty = true;
            state.items = [];
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(fetchDegree);

    // End fetch degree items list

    //  Start fetch single lecturer data

    const fetchData = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`lecturer/${root.$route.params.id}`)
        .then(({ data: { lecturer } }) => {
          model.data.firstName = lecturer.firstName || undefined;
          model.data.lastName = lecturer.lastName || undefined;
          model.data.degree = lecturer.degree || undefined;
          model.data.language = lecturer.languageVersion || undefined;
          model.data.category = lecturer.category || undefined;
          model.data.description = lecturer.description || undefined;

          state.oldImage = lecturer.image || undefined;

          model.data.institution = lecturer.institution || undefined;
          model.data.country = lecturer.country || undefined;
          model.data.city = lecturer.city || undefined;

          model.data.phone = lecturer.phoneNumber || undefined;
          model.data.email = lecturer.email || undefined;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    onMounted(fetchData);

    //  End fetch single lecturer data

    watch(
      () => model,
      (newModel) => emit("input", newModel),
      { deep: true }
    );

    const rules = {
      postalCode: [
        (v: string) =>
          !v ||
          validator.isPostalCode(v, "PL") ||
          "Podaj poprawny kod pocztowy",
      ],
      phoneNumberBasic: [
        (v: string) =>
          !v ||
          (v.length >= 9 && validator.isNumeric(v, { no_symbols: true })) ||
          "Podaj poprawny numer telefonu",
      ],
      email: [
        (v: string) =>
          !v ||
          validator.isEmail(v) ||
          root.$tc("layout.misc.validEmailAddress"),
      ],
    };

    watch(
      () => model.data.image,
      async (newPhoto) => {
        state.lecturerPhoto = (await toBase64(newPhoto)) as string;
      }
    );

    // Start update item

    const onSubmit = async () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;
      let imageId = state.oldImage?.id;

      const formData = new FormData();

      formData.append("image", model.data.image);

      if (model.data.image) {
        await axiosInstance
          .post("image/lecturer", formData)
          .then(({ data: { id } }) => {
            imageId = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        firstName: model.data.firstName,
        lastName: model.data.lastName,
        languageVersion: model.data.language || undefined,
        description: model.data.description || undefined,
        degree: model.data.degree || undefined,
        city: model.data.city || undefined,
        country: model.data.country || undefined,
        institution: model.data.institution || undefined,
        phoneNumber: model.data.phone || undefined,
        email: model.data.email || undefined,
        image: imageId || undefined,
      };

      state.loading = true;

      axiosInstance
        .put(`lecturer/${root.$route.params.id}`, data)
        .then(() => {
          state.success = true;
          model.data = {};
        })
        .catch((error) => {
          state.error = error.response.status;
        })
        .finally(() => (state.loading = false));
    };

    // End update item

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 403:
          return "Brak uprawnień";
        case 404:
          return "Endpointu nie znaleziono";
        case 409:
          return "Wykładowca już istnieje";
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return "Wystąpił bład";
      }
    };

    const filesUrl = computed(
      () => `${root.$store.state.api.baseURL}/static/laecturer/`
    );

    return {
      state,
      model,
      onSubmit,
      getErrorMessage,
      fetchData,
      languageItems,
      map,
      search,
      rules,
      filesUrl,
      countriesList,
    };
  },
});
